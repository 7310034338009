<template>
  <v-data-table
    :headers="headers"
    :items="orders"
    sort-by="name"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Orders</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="editedItem.order"
                      label="Order #"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-select
                      v-model="editedItem.status"
                      :items="statusOptions"
                      error-messages="selectErrors"
                      label="Category"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="printInvoice(item)"> mdi-eye </v-icon>
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { jsPDF } from "jspdf";
import img from "../../assets/base64/image";
import { db } from "../../firebase";
import "jspdf-autotable";

export default {
  data: () => ({
    statusOptions: ["Order Confirmed", "Checking Out", "Canceled", "Delivered"],
    orderRef: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Order #",
        align: "start",
        sortable: false,
        value: "order"
      },
      { text: "User", value: "user" },
      { text: "Delivery Date", value: "deliveryTime" },
      { text: "Phone #", value: "phone" },
      { text: "Total", value: "total" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      status: ""
    },
    defaultItem: {
      order: "",
      user: "",
      deliveryTime: 0,
      total: 0,
      phone: "",
      status: ""
    }
  }),

  beforeCreate: function() {
    this.$store.dispatch("getOrders");
  },

  computed: {
    orders() {
      return this.$store.getters.orders;
    },
    formTitle() {
      return this.editedIndex === -1 ? "View Order" : "Edit Product";
    },
    user() {
      return this.$store.getters.user;
    },

    order() {
      return this.$store.getters.orderId.toString();
    },

    cartTotal() {
      let total = this.currency.value * this.$store.getters.total;
      return total;
    },
    zwlRate() {
      let currencies = this.$store.getters.currencies;
      let found = currencies.find(currency => currency.denom == "$ZWL");
      return found.value;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    initialize() {
      this.$store.dispatch("getOrders");
    },
    editItem(item) {
      this.orderRef = item.id;
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    bodyRows(cart) {
      var body = [];
      cart.forEach(item => {
        var itemTotal = item.price * item.quantity;
        body.push([
          item.name,
          item.quantity + " x " + item.unit,
          itemTotal.toFixed(2)
        ]);
      });
      return body;
    },
    printInvoice(order) {
      var cartBody = this.bodyRows(order.cart);
      var total = order.total;
      var doc = new jsPDF();
      var imgData = img;
      doc.setFillColor(73, 160, 16);
      doc.rect(0, 0, 210, 12, "FD");
      doc.setTextColor(255, 255, 255);
      doc.text("DELIVERY ORDER", 80, 8);
      doc.addImage(imgData, "PNG", 120, 20, 50, 25);
      doc.autoTable({
        tableWidth: 70,
        theme: "plain",
        head: [["Delivery Details"]],
        body: [
          [order.receipient],
          [order.destination],
          [order.phone],
          ["Due on: " + order.deliveryTime]
          // ...
        ]
      });
      doc.autoTable({
        tableWidth: 70,
        margin: 125,
        theme: "grid",
        head: [["item", "Detail"]],
        body: [
          ["Order #", order.order],
          ["Ordered By", order.user],
          ["Order Date", order.transactionDate]
        ],
        showHead: false,
        columnStyles: {
          item: {
            fillColor: [41, 128, 185],
            textColor: 255,
            fontStyle: "bold"
          }
        }
      });
      doc.autoTable({
        theme: "grid",
        headStyles: {
          fillColor: [73, 160, 16]
        },
        head: [["item Description", "Quantity", "Amount"]],
        body: cartBody,
        columnStyles: {
          item: {
            fillColor: [41, 128, 185],
            textColor: 200,
            fontStyle: "bold"
          }
        }
      });
      doc.autoTable({
        tableWidth: 40,
        margin: { left: 155 },
        headStyles: {
          fillColor: [73, 160, 16]
        },
        theme: "grid",
        head: [["", "Total Due"]],
        body: [
          ["USD$", total],
          ["ZWL$", total * order.zwlRate]
          // ...
        ]
      });
      doc.output("dataurlnewwindow");
    },
    save() {
      var order = this.editedItem;
      var updatedOrder = db.collection("orders").doc(this.orderRef);
      if (this.editedIndex > -1) {
        updatedOrder
          .update({
            status: order.status
          })
          .then(() => {
            this.close();
          })
          .catch(err => {
            console.log(err);
            this.close();
          });
      }
    }
  }
};
</script>
